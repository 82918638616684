import React, { useState, useEffect } from 'react';
import Autosuggest from 'react-autosuggest';
import axios from 'axios';

// Funktion zum Rendern der Autosuggest-Eingabe
const renderSuggestion = (suggestion) => (
  <div>
    {suggestion.ansprechpartner_firmenname} - {suggestion.ansprechpartner_name}
  </div>
);

function AnsprechpartnerAutoSuggest({ onAnsprechpartnerSelected }) {
  const [value, setValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  // Laden der Ansprechpartnerdaten aus der API
  useEffect(() => {
    axios.get('https://db.xocore.de/aps')
      .then(response => {
        setSuggestions(response.data);
      })
      .catch(error => {
        console.error('Fehler beim Laden der Ansprechpartnerdaten:', error);
      });
  }, []);

  // Gibt den zu sendenden Wert zurück (ID des Ansprechpartners)
  const getSuggestionValue = (suggestion) => suggestion.ansprechpartner_id.toString();

  // Funktion zum Aktualisieren des Eingabewerts
  const onChange = (event, { newValue }) => {
    setValue(newValue);
  };

  // Vorschläge basierend auf der Eingabe filtern
  const onSuggestionsFetchRequested = ({ value }) => {
    const filteredSuggestions = suggestions.filter(suggestion =>
      suggestion.ansprechpartner_firmenname.toLowerCase().includes(value.toLowerCase()) ||
      suggestion.ansprechpartner_name.toLowerCase().includes(value.toLowerCase())
    );
    setSuggestions(filteredSuggestions);
  };

  // Vorschläge löschen, wenn der Nutzer die Eingabe löscht
  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  // Callback für den Fall, dass ein Vorschlag ausgewählt wird
  const onSuggestionSelected = (event, { suggestion }) => {
    // Nur die ID an den übergeordneten Handler weitergeben
    onAnsprechpartnerSelected(suggestion);
    setValue(`${suggestion.ansprechpartner_firmenname} - ${suggestion.ansprechpartner_name}`); // Optional: Wert im Eingabefeld anzeigen
  };

  // Input Props für das Autosuggest-Feld
  const inputProps = {
    placeholder: 'Ansprechpartner suchen',
    value,
    onChange,
    className: 'form-control', // Sie können hier auch Tailwind-Klassen verwenden
  };

  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      onSuggestionSelected={onSuggestionSelected}
      inputProps={inputProps}
      theme={{
        container: 'autosuggest-container',
        suggestionsContainer: 'dropdown',
        suggestionsList: 'list-group',
        suggestion: 'list-group-item',
      }}
    />
  );
}

export default AnsprechpartnerAutoSuggest;
