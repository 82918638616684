import React, { Fragment, useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import Accordion from 'react-bootstrap/Accordion';
import ProjektAuftrag from "./projekt_auftrag_element";
import FileUpload from "./FileUpload";
import FileElement from "./file-element";
import useAdminCheck from "./rechte_admin";
import NewAnsprechpartnerModal from "./av/NeuerAnsprechpartner";
import { Tooltip as ReactTooltip } from 'react-tooltip';
import AnsprechpartnerAutoSuggest from "./ansprechpartner_autosuggest";
import RechnungsempfängerAutoSuggest from "./rechnungsempfaenger_autosuggest";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';


function Ansprechpartner_wechsel_element() {

  useAdminCheck();
  const { projektnummer } = useParams();
  const [auftrage, setAuftrage] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [projekt, setProjekt] = useState(null);
  const[ap, setAP] =useState(null);
  const [rap,setRAP]=useState(null);
  const[material,setMaterial]= useState([]);
  const [files, setFiles] = useState([]);
  const [buttonColor, setButtonColor] = useState('danger');


// Zustand für den ausgewählten Ansprechpartner
const [selectedAnsprechpartner, setSelectedAnsprechpartner] = useState(null);
const [selectedRechnungsempfänger, setSelectedRechnungsempfänger] = useState(null);

// Callback-Funktion, um den ausgewählten Ansprechpartner zu setzen
const handleSelectedAnsprechpartner = (ansprechpartner) => {
  setSelectedAnsprechpartner(ansprechpartner);
  const selectedAnsprechpartnerId = ansprechpartner ? ansprechpartner.ansprechpartner_id : null;
  setEditableData(prevState => ({
    ...prevState,
    projekt_ansprechpartner: selectedAnsprechpartnerId, // Hier wird der ausgewählte Ansprechpartner gesetzt
  }));
  console.log('Ausgewählte Ansprechpartner Id:', selectedAnsprechpartnerId);
};
const handleRechnungsempfängerSelected = (rechnungsempfänger) => {
    // Setzen Sie den ausgewählten Rechnungsempfänger im Zustand
    setSelectedRechnungsempfänger(rechnungsempfänger);
    const selectedRechnungId = rechnungsempfänger ? rechnungsempfänger.ansprechpartner_id : null;
    setEditableData(prevState => ({
      ...prevState,
      projekt_rechnungsempfaenger: selectedRechnungId, // Hier wird der ausgewählte Ansprechpartner gesetzt
    }));
    console.log('Ausgewählte Rechnung Id:', selectedRechnungId);
  };


  const convertISODateToInputFormat = (isoDate) => {
    if (!isoDate) return ""; // Sicherstellen, dass ein Datum vorhanden ist
  
    const datePortion = isoDate.split("T")[0];
    return datePortion;
  };


  


  //Statusmeldungen für den Ansprechpartner
  const [showModal, setShowModal] = useState(false);
  const handleModalClose = () => setShowModal(false);
  const handleModalShow = () => setShowModal(true);



  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [alertMessage, setAlertMessage] = useState("");


  useEffect(() => {
    // Lade Dateien beim Mounten der Komponente
    axios.get(`https://db.xocore.de/projekt-material/${projektnummer}`)
      .then(response => {
        setFiles(response.data);
      })
      .catch(error => {
        console.error("Fehler beim Abrufen der Dateien:", error);
      });
  }, [projektnummer]);


  let editableDataInitialState = {
    projekt_id: "",
    projekt_auftraggeber: "",
    projekt_thema: "",
    projekt_beschreibung: "",
    projekt_umfang: "",
    projekt_tagessatz: "",
    projekt_umsatzsteuer: "",
    projekt_status: "",
    projekt_ma: "",
    projekt_notiz:"",
    projekt_beginn:"",
    projekt_ende:"",
    ansprechpartner_name: "",
    ansprechpartner_firmenname: "",
    ansprechpartner_mail: "",
    ansprechpartner_ort: "",
    ansprechpartner_plz: "",
    ansprechpartner_strasse: "",
    ansprechpartner_telefon: "",
    r_ansprechpartner_name: "",
    r_ansprechpartner_firmenname: "",
    r_ansprechpartner_mail: "",
    r_ansprechpartner_ort: "",
    r_ansprechpartner_plz: "",
    r_ansprechpartner_strasse: "",
    r_ansprechpartner_telefon: "",
  };

  const [materialList, setMaterialList] = useState([]);
  const [selectedMaterial, setSelectedMaterial] = useState("");
  const [quantity, setQuantity] = useState("");

  

  const handleAddOrder = () => {
    // Send data to the "mt-bestellen" endpoint
    const orderData = {
      material: selectedMaterial,
      quantity: quantity,
    };

    axios.post(`https://db.xocore.de/mt-bestellen/${projektnummer}`, orderData)
      .then(response => {
        console.log("Order successfully placed:", response.data);
        // Close the popup
        setShowModal(false);
      })
      .catch(error => {
        console.error("Error placing order:", error);
      });
  };

  useEffect(() => {
    // Lade Materialien beim Mounten der Komponente
    axios.get("https://db.xocore.de/mt-list")
      .then(response => {
        setMaterialList(response.data);
      })
      .catch(error => {
        console.error("Fehler beim Abrufen der Materialien:", error);
      });
  }, []);



  const [editableData, setEditableData] = useState(editableDataInitialState);
  useEffect(() => {
    // Hier sollte die entsprechende Auftrags-ID abgerufen werden (z.B., indem du Axios verwendest)
    axios.get(`https://db.xocore.de/projekt/${projektnummer}`)
      .then(response => {
        setProjekt(response.data);
        console.log('Projekt-Daten:', response.data);

        // Hier editableData aktualisieren
        setEditableData(prevData => ({
          ...prevData,
          projekt_id: response.data.projekt_id,
          projekt_auftraggeber: response.data.projekt_auftraggeber,
          projekt_thema: response.data.projekt_thema,
          projekt_beschreibung: response.data.projekt_beschreibung,
          projekt_umfang: response.data.projekt_umfang,
          projekt_tagessatz: response.data.projekt_tagessatz,
          projekt_umsatzsteuer: response.data.projekt_umsatzsteuer,
          projekt_status: response.data.projekt_status,
          projekt_ma: response.data.projekt_ma,
          projekt_km: response.data.projekt_kmtyp,
          projekt_notiz: response.data.projekt_notiz,
          projekt_beginn: response.data.projekt_beginn,
          projekt_ende: response.data.projekt_ende,
          projekt_ansprechpartner: response.data.projekt_ansprechpartner,
          projekt_rechnungsempfaenger: response.data.projekt_rechnungsempfaenger,



        }));
      })
      .catch(error => {
        console.error('Error fetching auftrag details:', error);
      });
}, [projektnummer]);

console.log(editableData.projekt_km)
  
  useEffect(() => {
    // Hier sollte die entsprechende Auftrags-ID abgerufen werden (z.B., indem du Axios verwendest)
    axios.get(`https://db.xocore.de/projekt/${projektnummer}`)
      .then(response => {
        setProjekt(response.data);
        console.log('Projekt-Daten:', response.data);
      })
      .catch(error => {
        console.error('Error fetching auftrag details:', error);
      });
  }, [projektnummer]); // Das Dependency-Array stellt sicher, dass useEffect bei Änderungen von auftragsnummer ausgeführt wird



  useEffect(() => {
    // Daten für die spezifische Projektnummer abrufen
    axios.get(`https://db.xocore.de/projekt/${projektnummer}/auftraege`)
      .then(response => {
        setAuftrage(response.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, [projektnummer]); // Das Dependency-Array stellt sicher, dass useEffect bei Änderungen der Projektnummer ausgeführt wird

  const filteredAuftrage = auftrage.filter(auftrag => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    return (
      auftrag.auftrag_thema?.toLowerCase().includes(lowerCaseSearchTerm) ||
      auftrag.auftrag_trainer?.toLowerCase().includes(lowerCaseSearchTerm)
    );
  });



  useEffect(() => {
    // Lade Projektdaten beim Mounten der Komponente
    axios.get(`https://db.xocore.de/mt/${projektnummer}`)
      .then(response => {
        setMaterial(response.data);
        console.log('Material-Daten:', response.data);
      })
      .catch(error => {
        console.error("Fehler beim Abrufen der Projektdaten:", error);
      });
  }, []);


  
  
  if (!projekt) {
    return <div>Loading...</div>;
  }





  
// Event-Handler für Änderungen an den Formularfeldern
const handleInputChange = (e) => {
  const { name, value } = e.target;

  // Ersetze Komma durch Punkt im Wert
  const updatedValue = value.replace(',', '.');

  setEditableData((prevData) => ({
    ...prevData,
    [name]: updatedValue,
  }));
};

  const handleSpeichernClick = () => {
    // Hier kannst du die zu speichernden Daten aus den State-Variablen abrufen

    setAlertType("success");
    setAlertMessage("Erfolgreich gespeichert!");
    setShowAlert(true);

    const { projekt_id, projekt_auftraggeber, projekt_thema, projekt_beschreibung, projekt_umfang, projekt_tagessatz, projekt_umsatzsteuer, projekt_status,projekt_ma,projekt_kmtyp,projekt_notiz,projekt_beginn,projekt_ende, /* andere Daten */ } = projekt;

    // Erstelle ein Objekt mit den zu aktualisierenden Daten
    const updatedData = {
      projekt_id,
      projekt_auftraggeber,
      projekt_thema: editableData.projekt_thema,
      projekt_beschreibung: editableData.projekt_beschreibung,
      projekt_umfang: editableData.projekt_umfang,
      projekt_tagessatz: editableData.projekt_tagessatz,
      projekt_umsatzsteuer: editableData.projekt_umsatzsteuer,
      projekt_status: editableData.projekt_status,
      projekt_ma: editableData.projekt_ma,
      projekt_ansprechpartner: editableData.projekt_ansprechpartner,
      projekt_rechnungsempfaenger: editableData.projekt_rechnungsempfaenger,
      projekt_kmtyp: editableData.km,
      projekt_notiz: editableData.notiz,
      projekt_beginn: editableData.beginn, 
      projekt_ende: editableData.ende,


      
    };


    console.log('Daten der Aktualisierung:',updatedData );
    // Sende die Anfrage an den Server, um die Daten zu aktualisieren
    axios.put(`https://db.xocore.de/projekt/${projektnummer}`, updatedData)
      .then(response => {
        console.log('Daten erfolgreich aktualisiert:', response.data);
        // Optional: Aktualisiere den State, um die Änderungen sofort auf der Oberfläche anzuzeigen
        setProjekt(response.data);

        setAlertType("success");
        setAlertMessage("Erfolgreich gespeichert!");
        setShowAlert(true);
    
      })
      .catch(error => {
        console.error('Fehler beim Aktualisieren der Daten:', error);
      });
  };

  if (Array.isArray(material)) {
    // Hier kannst du die map-Funktion sicher verwenden
    material.map(item => console.log(item));
  } else {
    console.error("material ist kein Array:", material);
  }
  




  return (
    <Fragment>




      <div className="container mt-6">
        <div className="row">
          <div className="col-md-12">
          </div>

        </div>
        <div className="row">
          <div className="col-md-12">
            {/* Hier kannst du den Inhalt für die Projektansicht hinzufügen */}
            <form className="row g-3">

     
      <div className="bg-light border"></div>
      <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>Fachlicher Ansprechpartner {projekt.projekt_ansprechpartner}</Accordion.Header>
        <Accordion.Body>
        <button  type="button" onClick={handleModalShow} className="btn btn-danger mb-2 w-100">Neuer Ansprechpartner</button>
        <FontAwesomeIcon icon={faInfoCircle} data-tooltip-content="Wirklich nur neue Ansprechpartner anlegen." data-tooltip-id="my-tooltip" data-placement="top" data-bs-toggle="tooltip"  />
        <ReactTooltip id="my-tooltip" place="top" effect="solid"></ReactTooltip>
        <AnsprechpartnerAutoSuggest onAnsprechpartnerSelected={handleSelectedAnsprechpartner} />

      </Accordion.Body>
      </Accordion.Item>
      <div className="bg-light border"></div>

      <Accordion.Item eventKey="1">
        <Accordion.Header>Rechnungsempfänger {projekt.projekt_rechnungsempfaenger}</Accordion.Header>
        <Accordion.Body>
        <button  type="button" onClick={handleModalShow} className="btn btn-danger mb-2 w-100">Neuer Ansprechpartner</button>
        <FontAwesomeIcon icon={faInfoCircle} data-tooltip-content="Wirklich nur neue Ansprechpartner anlegen." data-tooltip-id="my-tooltip" data-placement="top" data-bs-toggle="tooltip"  />
        <ReactTooltip id="my-tooltip" place="top" effect="solid"></ReactTooltip>
      
          <label htmlFor="Ansprechpartner" className="form-label">Rechnungsempfänger</label>
          <RechnungsempfängerAutoSuggest onRechnungsempfängerSelected={handleRechnungsempfängerSelected} />
       
      </Accordion.Body>
      </Accordion.Item>

     
      </Accordion>
      <div className="col-md-12">
          <button type="button" className={`btn btn-${buttonColor} mb-2 w-100`}  onClick={handleSpeichernClick}>
                Speichern
              </button>
          </div>
      </form>
            
          </div>
 

        </div>
 
      
      </div>

      <NewAnsprechpartnerModal show={showModal} handleClose={handleModalClose} />
    </Fragment>
  );
}


export default Ansprechpartner_wechsel_element;
