import React, { Fragment } from 'react';
import axios from 'axios';
import Accordion from 'react-bootstrap/Accordion';

const Release = () => {
  // Funktion zum Herunterladen des PDFs
  const handleDownloadPDF = async (trainerName) => {
    try {
      const response = await axios.post(
        `https://db.xocore.de/trainer/certificate/${trainerName}`,
        null, // Kein Body notwendig, nur der Trainername
        {
          responseType: "blob", // Wichtig: Antwort als Blob (Binary Large Object)
        }
      );
      // Sicherstellen, dass wir ein PDF bekommen haben
      if (response.status === 200) {
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `certificate_${trainerName}.pdf`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url); // Speicher freigeben
      } else {
        console.error("Fehler beim Abrufen des PDFs. Status:", response.status);
      }
    } catch (error) {
      console.error("Fehler beim Herunterladen des PDFs:", error);
    }
  };

  const trainerName = localStorage.getItem("Vollname");


  return (
    <Fragment>
      <div className="container mt-5">
        <h2 className="text-center mb-4">Coming Soon</h2>
        <div className="row">
          <h4>Bitte bereite dich vor und arbeite die Checkliste durch</h4>
          <div className="col-md-6">
            <form>
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Benutzerdaten</Accordion.Header>
                  <Accordion.Body>
                    <div className="mb-3">
                      Passe deine Benutzerdaten an. Achte insbesondere auf deine Adresse.
                    </div>
                    <button type="submit" className="btn btn-primary">Ins Profil springen</button>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="2">
                  <Accordion.Header>Rechnungsdaten</Accordion.Header>
                  <Accordion.Body>
                    <div className="mb-4">
                      Deine Rechnungen werden als XRechnungen erstellt.
                      Pflege bitte daher deine Steuerdaten und deine Rechnungsnummer.
                      Die Rechnungsnummer ist eine fortlaufende rein nummerische Folge. In der App wird Sie wie folgt ausgegeben:
                      JAHR-$$$$. Die Dollarzeichen stehen für den aktuellen Zähler den du in den Einstellungen vorgibst.
                    </div>
                    <button type="submit" className="btn btn-primary">Ins Profil springen</button>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="1">
                  <Accordion.Header>Steuern/Rechnung</Accordion.Header>
                  <Accordion.Body>
                    <div className="mb-4">
                      Pflege deine Steuerdaten in der App. Das Feld Umsatzsteuerregel belegst du mit einer "1", sofern du Umsatzsteuer ausweisen möchtest auf deiner Rechnung. Ansonsten wird keine Umsatzsteuer aufgrund der "Kleinunternehmensregel" berechnet. Bitte pflege alle restlichen Felder.
                    </div>
                    <button type="button" className="btn btn-primary">Steuerdaten ändern</button>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </form>
          </div>

          <div className="col-md-6 d-flex justify-content-end align-items-start">
            <h4>Umsatzsteuerbescheinigung - 2024</h4>
            <button
              type="button"
              onClick={() => handleDownloadPDF(trainerName)}
              className="btn btn-success"
            >
              herunterladen
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Release;
