import React, { useState } from 'react';
import axios from 'axios';
import useAdminCheck from './rechte_admin';


const NewsForm = () => {
  useAdminCheck();
  const [update, setUpdate] = useState('');
  const [message, setMessage] = useState('');


  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      // Benutzerdaten an den Server senden
      const response = await axios.put('https://db.xocore.de/news', { update });
      setMessage(response.data.message);
    } catch (error) {
      setMessage('Fehler beim Eintragen der News');
      console.error('Fehler beim Registrieren des Benutzers:', error);
    }
  };

  return (
    <div className="container mt-5">
      <h2 className="text-center mb-4">Nachrichteneintrag erstellen</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <textarea type="text" id="update" className="form-control" value={update} onChange={(e) => setUpdate(e.target.value)} required />
        </div>

        <button type="submit" className="btn btn-primary">Nachricht Posten</button>
      </form>
      {message && <p className="mt-3 text-center">{message}</p>}
    </div>
  );
};

export default NewsForm;
